
import { defineComponent ,reactive } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import LongBox from "./modules/longBoxa.vue";
import MiddleBox from "./modules/middleBox.vue";
import Message from "@/components/message/Message.vue";
import LittleMessage from "./modules/littleMessage.vue";
import { useRoute, useRouter } from "vue-router";
import axios from "@/utils/axios";

// import Tag from "./modules/Tag.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    LongBox,
    MiddleBox,
    Message,
    LittleMessage,
  },
  data() {
    return {
      tabPosition: "left",
      currentPage1: 1,
      currentPage2: 1,
      messList: [
        {
          id: 1,
          mess: "高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 2,
          mess: "2高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
        {
          id: 3,
          mess: "3高考新政自2014年实施以来，截至目前北京、天津、山东、海南、河北、湖南、",
        },
      ],
      demoImg: require("@/assets/1new/关于我们/关于我们.jpg"),
      videoS: require("@/assets/关于我们/djx.mp4"),
    };
  },
  methods: {
    goDetail(id: any) {
      // console.log(id);
      this.$router.push({ name: "newsDetail", params: { id: id } });
    },
    handleSizeChange(val: any) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val: any) {
      console.log(`当前页: ${val}`);
    },
    handleSizeChange2(val: any) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange2(val: any) {
      console.log(`当前页: ${val}`);
    },
  },
   setup() {
    // const router = useRouter();
    const route = useRoute();
    const video = reactive({ data: "" });
    // console.log(router,'1111111',route);
    // console.log(route,"route");
    console.log(route.params, "route");
    axios
      .get(
        "https://www.xsy985.com/api/course/course/" + route.params.id
      )
      .then(function (response) {
        console.log(response.data, "video");
        video.data = response.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    return {
      video,
    };
  },
});
