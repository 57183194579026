<template>
  <div class="littleMessage">
    <div class="wrap">
      <p class="type">小新快讯</p>
      <p class="more">查看更多</p>
      <div class="box" v-for="(item, i) in list" :key="i">
        <!-- <div class="title">{{item.title}}</div> -->
        <div class="thumnail"></div>

        <div class="content">{{ item.content }}</div>
        <div class="line" v-if="i < 1"></div>
      </div>
    </div>
    <div class="line2"></div>

    <div class="wrap">
      <p class="type">行业动态</p>
      <p class="more">查看更多</p>
      <div class="box" v-for="(item, i) in list" :key="i">
        <!-- <div class="title">{{item.title}}</div> -->
        <div class="thumnail"></div>
        <div class="content">{{ item.content }}</div>
        <div class="line" v-if="i < 1"></div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "swiper",
  data() {
    return {
      imgList: [
        // require("@/assets/首页/轮播/4-2.png"),
        require("@/assets/1new/生涯资讯/生涯资讯.jpg"),
      ],
      list: [
        {
          title: "1",
          content:
            "送大的那三單啊賽單啊開機送大的那三單啊賽單啊開機送大的那三單啊賽單啊開機送大的那三單啊賽單啊開機送大的那三單啊賽單啊開機送大的那三單啊賽單啊開機",
        },
        { title: "2", content: "送大的那三單啊賽單啊開機" },
      ],
    };
  },
  methods: {
    goXSY() {
      // this.$router.push("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard");
      // window.open("https://cloud.xsygh.com/#/login?redirect=%2Fdashboard")
    },
  },
});
</script>
<style lang='scss' scoped>
.littleMessage {
  .line {
    width: 230px;
    height: 1px;
    background-color: #3ebbbd;
    margin: 30px 0;
  }
  .line2 {
    width: 230px;
    float: left;
    height: 2px;
    background-color: #3ebbbd;
    margin: 30px 0;
  }
  .wrap {
    width: 230px;
    display: block;
    .type {
      font-size: 24px;
      color: #000;
      font-weight: bold;
      float: left;
      margin-bottom: 30px;
    }
    .more {
      float: right;
      font-size: 14px;
      margin-top: 10px;
      margin-bottom: 30px;
      cursor: pointer;
    }

    .box {
      float: left;
      width: 230px;
      background-color: #fff;
      .thumnail {
        width: 230px;
        height: 150px;
        background-color: pink;
        margin-bottom: 10px;
      }
      .title {
        font-size: 16px;
        color: #3ebbbd;
        line-height: 20px;
        margin-bottom: 30px;
      }
      .content {
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
         line-height: 20px;
        font-size: 14px;
      }
    }
  }
}
</style>
